[data-target] {
  cursor: pointer;
}

@font-face {
  src: url("../asset/font/Noto_Sans_JP/NotoSansJP-Regular.woff") format("woff");
  font-family: "Noto Sans JP";
  font-style: normal;
  font-display: swap;
  font-weight: normal;
}

@font-face {
  src: url("../asset/font/Noto_Sans_JP/NotoSansJP-Bold.woff") format("woff");
  font-family: "Noto Sans JP";
  font-style: normal;
  font-display: swap;
  font-weight: bold;
}

@font-face {
  src: url("../asset/font/Ubuntu-Bold.woff") format("woff");
  font-family: "Ubuntu";
  font-style: normal;
  font-display: swap;
  font-weight: bold;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  overflow-wrap: break-word;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  letter-spacing: 0.03em;
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  /*   font-feature-settings: "palt"; */
  font-size: 1.5rem;
  font-family: "Noto Sans JP";
  font-style: normal;
  line-height: 1.5;
  font-weight: 400;
  color: #333;
  text-align: left;
}

body[data-loading="true"] {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 767px) {
  body {
    font-size: 1.4rem;
    min-width: unset;
  }
}

ul,
li {
  list-style: none;
}

article,
aside,
footer,
header,
nav,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: top;
}

video {
  max-width: 100%;
  height: auto;
}

code,
kbd,
pre,
samp {
  font-size: inherit;
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}

th,
td {
  padding: 4px 6px;
  text-align: left;
  vertical-align: top;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
  content: "";
  content: none;
}

address {
  font-style: normal;
}

.main {
  margin-top: 150px;
}

@media (max-width: 767px) {
  .main {
    margin-top: 60px;
  }
}

.inner {
  margin: 0 auto;
  padding: 0;
  max-width: 100%;
  width: 1080px;
  height: inherit;
  position: relative;
}

@media (max-width: 1128px) {
  .inner {
    padding-right: 24px;
    padding-left: 24px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .inner {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.section-ttl {
  font-size: 4.8rem;
  font-weight: bold;
  font-family: "Ubuntu";
  display: inline-block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.01em;
  line-height: 150%;
  color: #0072c2;
  text-transform: uppercase;
}

@media (max-width: 1128px) {
  .section-ttl {
    text-align: center;
    font-size: 4.4rem;
  }
}

@media (max-width: 767px) {
  .section-ttl {
    font-size: 3.2rem;
  }
}

.section-ttl span {
  font-size: 1.5rem;
  letter-spacing: 0.03em;
  position: relative;
  padding: 0 12px;
  line-height: 180%;
  color: #333;
}

.section-ttl span:before, .section-ttl span:after {
  content: "";
  height: 1px;
  background: #333;
  width: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.section-ttl span:before {
  right: 100%;
}

.section-ttl span:after {
  left: 100%;
}

.section-ttl._white {
  color: #fff;
}

.section-ttl._white span {
  color: #fff;
}

.section-ttl._white span:before,
.section-ttl._white span:after {
  background: #fff;
}

.sec {
  padding: 80px 0;
}

@media (max-width: 767px) {
  .sec {
    padding: 48px 0;
  }
}

.inner-mv {
  position: relative;
  background: #09096f;
  background: linear-gradient(115deg, #0063a8 39%, #0072c2 39%);
  height: 270px;
}

@media (max-width: 767px) {
  .inner-mv {
    height: 164px;
    background: linear-gradient(115deg, #0063a8 42%, #0072c2 42%);
  }
}

.inner-mv-ttl {
  color: #fff;
  font-size: 4.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: bold;
  justify-content: center;
  height: 100%;
}

.inner-mv-ttl span {
  margin-top: 16px;
  color: #338ece;
  font-family: "Ubuntu";
  font-size: 1.8rem;
  line-height: 180%;
  position: relative;
  padding-left: 80px;
  text-transform: uppercase;
}

.inner-mv-ttl span:before {
  content: "";
  background-color: #338ece;
  width: 64px;
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1128px) {
  .inner-mv-ttl {
    font-size: 3.8rem;
  }
}

@media (max-width: 767px) {
  .inner-mv-ttl {
    font-size: 2.4rem;
  }
  .inner-mv-ttl span {
    font-size: 1.5rem;
    margin-top: 8px;
    padding-left: 40px;
  }
  .inner-mv-ttl span:before {
    width: 32px;
  }
}

.section-head {
  font-weight: bold;
  font-size: 3.2rem;
  font-weight: bold;
  position: relative;
  padding-bottom: 8px;
  border-bottom: 4px solid #cce3f3;
}

.section-head:after {
  position: absolute;
  top: 100%;
  left: 0;
  height: 4px;
  background: #0072c2;
  width: 360px;
  content: "";
}

@media (max-width: 1128px) {
  .section-head {
    font-size: 2.8rem;
  }
}

@media (max-width: 767px) {
  .section-head {
    font-size: 2rem;
  }
  .section-head:after {
    width: 112px;
  }
}

.btn {
  border: 1px solid #0072c2;
  color: #0072c2;
  background: #fff;
  line-height: 1.8;
  font-weight: bold;
  width: 280px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  position: relative;
  margin: auto;
  font-size: 1.5rem;
}

.btn:hover {
  background: #0072c2;
  color: #fff;
}

.btn:hover:before {
  background-image: url(../asset/images/common/arrow04.svg);
}

.btn:before {
  position: absolute;
  transition: 0.2s;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  background-image: url(../asset/images/common/arrow02.svg);
  background-size: contain;
  width: 12px;
  height: 12px;
  content: "";
}

.drawer {
  z-index: 40;
  height: auto;
  width: 100%;
}

@media (max-width: 767px) {
  .drawer {
    width: 60px;
    height: 60px;
    background: #0072c2;
    position: absolute;
    right: 0;
    top: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
}

.drawer-close {
  display: none;
  position: fixed;
  z-index: 39;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.drawer-close.is-checked {
  display: block;
  opacity: 0.4;
}

.drawer-icon {
  display: none;
  position: relative;
  z-index: 41;
  text-align: center;
  cursor: pointer;
  background-color: inherit;
  border: none;
}

@media (max-width: 767px) {
  .drawer-icon {
    display: inline-block;
    padding: 0;
  }
}

.drawer-icon:focus {
  outline: none;
}

.drawer-icon::after {
  content: "MENU";
  font-size: 1.4rem;
  display: none;
  color: #333;
  text-align: center;
  line-height: 1;
  margin-top: 4px;
  font-weight: 300;
}

@media (max-width: 767px) {
  .drawer-icon::after {
    font-size: 1rem;
  }
}

.drawer-icon.is-checked::after {
  content: "CLOSE";
  /*       color: #fff; */
}

.drawer-icon.is-checked + body {
  overflow: hidden;
}

.drawer-bars {
  display: inline-block;
  width: 28px;
  height: 14px;
  position: relative;
  vertical-align: bottom;
}

@media (max-width: 767px) {
  .drawer-bars {
    width: 28px;
    height: 24px;
  }
}

.drawer-bars:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  content: "";
  z-index: -1;
  border-radius: 50%;
}

.drawer-bar {
  position: absolute;
  right: 0;
  left: 0;
  border-radius: 0px;
  display: block;
  background: #fff;
  width: 100%;
  height: 2px;
  transition: all 0.3s linear 0s;
}

.drawer-bar:nth-of-type(1) {
  top: 0;
}

.drawer-bar:nth-of-type(2) {
  top: 6px;
}

@media (max-width: 767px) {
  .drawer-bar:nth-of-type(2) {
    top: 11px;
  }
}

.drawer-bar:nth-of-type(3) {
  top: 12px;
}

@media (max-width: 767px) {
  .drawer-bar:nth-of-type(3) {
    top: 22px;
  }
}

.is-checked .drawer-bar:nth-of-type(1) {
  top: 10px;
  transform: rotate(-45deg);
  background: #fff;
}

.is-checked .drawer-bar:nth-of-type(2) {
  background: transparent;
}

.is-checked .drawer-bar:nth-of-type(3) {
  top: 10px;
  transform: rotate(45deg);
  background: #fff;
}

.drawer-content {
  height: 100%;
}

@media (max-width: 767px) {
  .drawer-content {
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    width: 100vw;
    max-width: 100%;
    height: 100%;
    background: #0072c2;
    transition: all 0.5s ease 0s;
    padding: 0;
    text-align: left;
    color: #fff;
    min-width: 1080px;
    padding: 60px 20px 0;
  }
  .drawer-content.is-checked {
    /*       transform: translateX(0); */
    top: 0px;
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 767px) {
  .drawer-content {
    min-width: unset;
  }
}

.drawer-content--top {
  /*   padding: 0; */
}

@media (max-width: 767px) {
  .drawer-content--top {
    transform: translateY(-105%);
    top: 0px;
    bottom: auto;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-width: auto;
  }
  .drawer-content--top.is-checked {
    transform: translateY(0);
    top: 0px;
    opacity: 1;
    visibility: visible;
  }
}

.g-nav {
  height: 100%;
}

@media (max-width: 767px) {
  .g-nav {
    height: auto;
  }
}

.g-nav-list {
  display: flex;
  height: 100%;
  width: 100%;
}

@media (max-width: 767px) {
  .g-nav-list {
    flex-direction: column;
  }
}

.g-nav-item {
  position: relative;
  height: 100%;
  width: calc(100% / 6);
}

@media (max-width: 767px) {
  .g-nav-item {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #338ece;
  }
  .g-nav-item:nth-of-type(1) {
    border-top: 1px solid #338ece;
  }
}

.g-nav-item:before, .g-nav-item:nth-last-of-type(1):after {
  content: "";
  height: 24px;
  width: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: #eaebec;
}

@media (max-width: 767px) {
  .g-nav-item:before, .g-nav-item:nth-last-of-type(1):after {
    display: none;
  }
}

.g-nav-item:nth-last-of-type(1):after {
  right: 0;
  left: auto;
}

.g-nav-item-link {
  width: 100%;
  height: 100%;
  transition: 0.3s;
  font-size: 1.5rem;
  line-height: 180%;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.03em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px 0;
}

.g-nav-item-link:hover, .g-nav-item-link.active {
  color: #0072c2;
}

@media (max-width: 767px) {
  .g-nav-item-link:hover, .g-nav-item-link.active {
    color: inherit;
  }
}

.g-nav-item-link:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  content: "";
  transition: 0.3s;
}

@media (max-width: 767px) {
  .g-nav-item-link {
    color: #fff;
    font-size: 1.5rem;
    padding: 16px 0;
    display: inline-block;
    text-align: left;
  }
  .g-nav-item-link:before {
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
    background: url(../asset/images/common/arrow04.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    left: auto;
  }
}

.breadcrumb {
  margin-top: 24px;
}

@media (max-width: 767px) {
  .breadcrumb {
    margin-top: 16px;
  }
}

.breadcrumb-contents {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 1.4rem;
  line-height: 180%;
  letter-spacing: 1px;
}

.breadcrumb-contents span {
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.breadcrumb-contents a {
  transition: 0.2s;
}

.breadcrumb-contents a.home:hover {
  opacity: 0.7;
}

@media (max-width: 767px) {
  .breadcrumb-contents {
    font-size: 1.2rem;
  }
}

.breadspan {
  display: block;
  margin: 0 8px;
  width: 25px;
}

@media (max-width: 767px) {
  .breadspan {
    width: 22px;
    margin: 0 4px;
  }
}

.breadspan + span {
  color: #858585;
}

#dialog {
  background: rgba(0, 0, 0, 0.2);
  height: 100vh;
  position: fixed;
  top: 0;
  transition: 0.3s;
  width: 100vw;
  z-index: 9999999999;
  visibility: hidden;
  opacity: 0;
}

#dialog .js-active {
  visibility: visible;
  opacity: 1;
}

.dialog-box {
  background: linear-gradient(90deg, #6b3dea 0%, #9781e3 100%);
  box-shadow: 0px 2px 5px rgba(117, 75, 237, 0.25);
  left: 50%;
  padding: 30px 20px;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  z-index: 9999999999;
}

@media (max-width: 767px) {
  #dialog .dialog-box {
    min-width: 250px;
  }
}

#dialog .dialog-text {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 20px;
  color: #fff;
}

@media (max-width: 1014px) {
  #dialog .dialog-text {
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

#dialog button {
  cursor: pointer;
  border-radius: 100px;
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 100%;
  width: 100%;
  transition: all 0.3s;
  box-shadow: 0px 2px 5px rgba(117, 75, 237, 0.25);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #754bed;
  border: 1px solid #754bed;
  background: #fff;
  margin: 40px auto 0;
  width: 216px;
  height: 57px;
}

#dialog button:hover {
  background: #754bed;
  border: 1px solid #fff;
  color: #fff;
}

/* =============================================================================


     footer


============================================================================= */
footer {
  background-color: #232f3e;
  padding: 64px 0;
  text-align: left;
  color: #fff;
}

@media (max-width: 767px) {
  footer {
    padding: 40px 0 24px;
  }
}

footer .inner {
  width: 1200px;
}

.footer-contents {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .footer-contents {
    flex-direction: column;
  }
}

.footer-logo {
  width: 184px;
}

@media (max-width: 767px) {
  .footer-logo {
    width: 151px;
  }
}

.footer-info-txt {
  margin-top: 16px;
  font-size: 1.2rem;
  font-weight: bold;
}

@media (max-width: 767px) {
  .footer-info-txt {
    margin-top: 12px;
  }
}

.footer-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 100px;
  min-width: 340px;
}

@media (max-width: 767px) {
  .footer-list {
    margin-top: 24px;
    max-height: 180px;
    min-width: unset;
  }
}

.footer-item {
  display: flex;
  min-width: 4em;
  font-size: 1.2rem;
  font-weight: bold;
}

.footer-item:nth-child(n + 4) {
  margin-left: 32px;
}

@media (max-width: 767px) {
  .footer-item:nth-child(n + 4) {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .footer-item {
    font-size: 1.2rem;
  }
  .footer-item:nth-child(n + 6) {
    margin-left: 48px;
  }
}

.footer-link {
  transition: 0.3s;
  display: inline-block;
}

.footer-link:hover {
  text-decoration-line: underline;
}

.copy {
  font-size: 1.2rem;
  margin-top: 12px;
  color: #7b828b;
}

@media (max-width: 767px) {
  .copy {
    margin-top: 24px;
    text-align: center;
    font-size: 1rem;
  }
}

/* =============================================================================


     header


============================================================================= */
header {
  position: relative;
  z-index: 20;
  height: 150px;
  width: 100%;
  background: #fff;
  transition: 0.2s;
  box-shadow: 0px 0px 10px rgba(51, 51, 51, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  border-top: 4px solid #0072c2;
}

@media (max-width: 767px) {
  header {
    height: 60px;
    border-top: 2px solid #0072c2;
  }
}

header .inner {
  width: 1200px;
  height: 100%;
}

.header-contents {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  transition: 0.2s;
  padding-top: 24px;
}

@media (max-width: 767px) {
  .header-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding-top: 0;
  }
}

.header-logo {
  display: inline-block;
}

.header-logo a {
  transition: 0.2s;
  display: inline-block;
  position: relative;
  width: 221px;
}

@media (max-width: 1128px) {
  .header-logo a {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .header-logo a {
    width: 166px;
    position: relative;
  }
}

.header-logo a:hover {
  opacity: 0.7;
}

@media (max-width: 767px) {
  .header-btn-pc {
    display: none;
  }
}

.header-btn-sp {
  display: none;
}

@media (max-width: 767px) {
  .header-btn-sp {
    display: block;
  }
}

.header-btn-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 767px) {
  .header-btn-list {
    flex-direction: column;
    margin-top: 32px;
  }
}

.header-btn-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-btn-item + li {
  margin-left: 20px;
}

@media (max-width: 767px) {
  .header-btn-item + li {
    margin: 32px auto 0;
  }
}

.header-tel-btn {
  font-weight: bold;
  transition: 0.2s;
  font-size: 3.2rem;
  color: #0072c2;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  line-height: 37px;
}

.header-tel-btn span {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
}

.header-tel-btn img {
  display: block;
  width: 32px;
}

.header-tel-btn:hover {
  opacity: 0.7;
}

@media (max-width: 767px) {
  .header-tel-btn {
    color: #fff;
  }
}

.header-tel-span {
  font-size: 1.2rem;
  line-height: 1.8;
  font-weight: bold;
}

.header-mail-btn {
  background: #e48f25;
  color: #fff;
  padding: 16px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.8;
  transition: 0.2s;
  border: 2px solid #e48f25;
}

.header-mail-btn:hover {
  color: #e48f25;
  background: #fff;
}

.header-mail-btn:hover span:before {
  background-image: url(../asset/images/common/header-mail-icon_h.svg);
}

.header-mail-btn span {
  padding-left: calc(18px + 12px);
  position: relative;
}

.header-mail-btn span:before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 18px;
  background-image: url(../asset/images/common/header-mail-icon.svg);
}

@media (max-width: 1128px) {
  .header-mail-btn {
    padding: 12px 28px;
  }
}

@media (max-width: 767px) {
  .header-mail-btn {
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 12px 32px;
  }
}

/* **************************************


     sub-footer


***************************************/
.sf {
  background-image: url(../asset/images/common/contact_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 64px 0;
  color: #fff;
  position: relative;
}

@media (max-width: 767px) {
  .sf {
    padding: 48px 0;
    background-image: url(../asset/images/common/contact_bg_sp.jpg);
    background-position: top;
  }
}

.sf:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #005692;
  opacity: 0.8;
}

.sf-contents {
  text-align: center;
  position: relative;
  z-index: 1;
}

.sf-ttl {
  font-size: 4.8rem;
  font-weight: bold;
  font-family: "Ubuntu";
  display: inline-block;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .sf-ttl {
    font-size: 3.2rem;
  }
}

.sf-ttl span {
  font-size: 1.5rem;
  letter-spacing: 0.03em;
  position: relative;
  padding: 0 48px;
}

.sf-ttl span:before, .sf-ttl span:after {
  content: "";
  height: 1px;
  background: #fff;
  width: 32px;
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
}

.sf-ttl span:before {
  left: 100%;
}

.sf-ttl span:after {
  right: 100%;
}

@media (max-width: 767px) {
  .sf-ttl span {
    padding: 0 12px;
  }
}

.sf-head {
  margin-top: 32px;
  font-weight: bold;
  line-height: 180%;
}

@media (max-width: 767px) {
  .sf-head {
    font-size: 1.5rem;
    text-align: justify;
    margin-top: 24px;
  }
}

.sf-flex {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .sf-flex {
    flex-direction: column;
    margin-top: 24px;
  }
}

.sf-flex-item {
  font-family: "Ubuntu";
  font-weight: bold;
  font-size: 6rem;
  line-height: 69px;
  letter-spacing: 0.01em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sf-flex-item img {
  width: 56px;
  display: block;
  margin-right: 8px;
}

@media (max-width: 1128px) {
  .sf-flex-item img {
    width: 46px;
  }
}

@media (max-width: 1128px) {
  .sf-flex-item {
    font-size: 4rem;
    letter-spacing: 0.01em;
    line-height: 46px;
    margin-top: 8px;
  }
}

.sf-btn-list {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .sf-btn-list {
    flex-direction: column;
  }
}

span.s {
  display: block;
  margin: 0 20px;
  font-family: "Ubuntu";
  font-weight: bold;
  font-size: 6rem;
}

@media (max-width: 1128px) {
  span.s {
    font-size: 4rem;
  }
}

@media (max-width: 767px) {
  span.s {
    display: none;
  }
}

.sf-mail-btn {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 40px;
  background: #e48f25;
  transition: 0.2s;
  color: #fff;
  position: relative;
  letter-spacing: 0.03em;
  font-weight: bold;
  line-height: 180%;
  border: 2px solid #e48f25;
}

.sf-mail-btn:hover {
  color: #e48f25;
  background: #fff;
}

.sf-mail-btn:hover span:before {
  background-image: url(../asset/images/common/header-mail-icon_h.svg);
}

.sf-mail-btn span {
  padding-left: calc(18px + 12px);
  position: relative;
}

.sf-mail-btn span:before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 18px;
  background-image: url(../asset/images/common/header-mail-icon.svg);
}

@media (max-width: 767px) {
  .sf-mail-btn {
    font-size: 1.5rem;
    padding: 20px 20px;
  }
}

.sf-mail-icon {
  width: 18px;
  margin-right: 12px;
}

.sf-arrow {
  width: 16px;
  margin-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
}

.sf-btn-item {
  margin: 32px auto 0;
}

@media (max-width: 767px) {
  .sf-btn-item {
    width: 100%;
    max-width: 340px;
    margin: 40px auto 0;
  }
  .sf-btn-item + li {
    margin: 18px auto 0;
  }
}

.pc-dis {
  display: none;
}

@media (max-width: 1366px) {
  .pc-dis {
    display: block;
  }
}

.tab-none {
  display: block;
}

@media (max-width: 1128px) {
  .tab-none {
    display: none;
  }
}

.tab-dis {
  display: none;
}

@media (max-width: 1128px) {
  .tab-dis {
    display: block;
  }
}

.tab-only {
  display: none;
}

@media (max-width: 1128px) {
  .tab-only {
    display: block;
  }
}

@media (max-width: 767px) {
  .tab-only {
    display: none;
  }
}

.sp-none {
  display: block;
}

@media (max-width: 767px) {
  .sp-none {
    display: none;
  }
}

.sp-dis {
  display: none;
}

@media (max-width: 767px) {
  .sp-dis {
    display: block;
  }
}
